import io from 'socket.io-client';
import CONFIG from '../config/index'

let socket = null;

export function getSocket() {
  if (!socket) {
    console.log('Socket.io connecting to', CONFIG.API_URL);
    socket = io.connect(CONFIG.API_URL, {
      path: '',
      transports: ['websocket'],
      timeout: 5000, // 5 seconds should be enough
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 10,
      randomizationFactor: 0,
      autoConnect: true
    });
  }

  return socket;
}

export const setupSocket = () => {
  if (socket) {
    // socket.disconnect()
    return
  }

  socket = getSocket();

  socket.on('connect_failed', function () {
    console.error('[Socket.io] connect_failed');
  });

  socket.on('connect', function () {
    console.log('Socket.io connected to ' + CONFIG.API_URL);
  });

  socket.on('disconnect', function () {
    console.log('Socket.io disconnected from ' + CONFIG.API_URL);
  });

  socket.on('close', function () {
    console.log('Socket.io closed connection to ' + CONFIG.API_URL);
  });

  socket.on('reconnect', function () {
    console.log('Attempting to reconnect to ' + CONFIG.API_URL);
  });
};

export const subscribeToChannel = (channel, cb) => {
  if (!socket) {
    console.log(`Subscription failed to ${channel} problem with setup socket.`)
  }

  console.log('Subscribing to channel =>', channel);

  socket.on(channel, cb);
};

export const unsubscribeFromChannel = async (channel) => {
  if (socket) {
    console.log('Unsubscribe from channel =>', channel);

    socket.removeListener(channel);

    socket.disconnect()
  }
};
