import { createStore as createReduxStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, } from 'redux-persist'
import { createLogger } from 'redux-logger'
import root from './reducers';

let store = null;

const createStore = (initialState = {}) => {
  const enhancers = [];
  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    }
  }

  const logger = createLogger({});

  const rootReducer = (state, action) => root(state, action)

  const store = createReduxStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(logger, thunk),
      ...enhancers
    )
  );

  let persistor = persistStore(store)

  return { store, persistor }
};

export const getStore = () => {
  if (!store) {
    store = createStore();
    return store;
  }

  return store;
};
