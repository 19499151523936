import React, { useState, useEffect, useMemo, useRef, } from 'react';
import { useSelector, useDispatch, } from 'react-redux'
import { useHistory } from "react-router-dom";
import './Dashboard.css';

import Timeline from '../../components/Timeline/Timeline'

import { eventColors } from '../../../../helpers/constants'
import { getFormattedDate, } from '../../../../helpers/dateFormatter'

import { setSideMenuState } from '../../../../reducers/ControlReducer'

function Dashboard() {
  const history = useHistory();

  const dispatch = useDispatch()

  const calendarRef = useRef()
  const interval = useRef()

  const userInfo = useSelector(state => state.user.info)
  const events = useSelector(state => state.calendars.events)
  const connected = useSelector(state => state.calendars.connected)

  const [viewType, setViewType] = useState('linked')

  const handleChangeViewType = (type) => setViewType(type)

  const linkedEventsList = useMemo(() => {
    return events.map((calendar, index) => {
      return calendar.events.map((event, key) => ({
        id: `${index}-${key}`,
        groupId: index,
        title: event.summary,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        borderColor: connected && connected[index] ? connected[index].background_color : eventColors[index],
        backgroundColor: connected && connected[index] ? connected[index].background_color : eventColors[index],
        className: 'event-container',
        links: event.urls,
      }))
    }).flat()
  }, [events])

  const unlinkedEventsList = useMemo(() => {
    return events.map((calendar, index) => {
      return ({
        events: calendar.events.map((event, key) => ({
          id: `${index}-${key}`,
          groupId: index,
          title: event.summary,
          start: new Date(event.start.dateTime),
          end: new Date(event.end.dateTime),
          borderColor: connected && connected[index] ? connected[index].background_color : eventColors[index],
          backgroundColor: connected && connected[index] ? connected[index].background_color : eventColors[index],
          className: 'event-container',
          links: event.urls,
        })),
        info: calendar,
      })
    })
  }, [events,])

  const toggleSidebar = () => {
    dispatch(setSideMenuState(true))
  }

  // if (loading) {
  //   return (
  //     <div className="centered">
  //       <div>Loading...</div>
  //     </div>
  //   )
  // }

  if (connected.length === 0) {
    return (
      <div className="centered">
        <div></div>
      </div>
    )
  }

  return (
    <div className='dashboard-container'>
      <div className='dashboard-events-list'>
        <div className='dashboard-heading-container'>
          <div 
            className='sidebar-icon-container'
            onClick={toggleSidebar}
          >
            <div className='sidebar-icon' />
          </div>
          <p className='events-heading'>Today is {getFormattedDate('MMMM Do, YYYY')}</p>
          <div className='events-view-buttons-container'>
            <button className={`events-view-button ${viewType === 'linked' ? 'active': ''}`} onClick={() => handleChangeViewType('linked')}>
              Linked
            </button>
            <button className={`events-view-button ${viewType === 'unlinked' ? 'active' : ''}`} onClick={() => handleChangeViewType('unlinked')}>
              Unlinked
            </button>
          </div>
        </div>

        <div className='list'>
          {viewType === 'linked' ? (
            <div>
              <div className='events-calendar-legend'>
                {unlinkedEventsList.map((calendar, index) => {
                  const currentCalendar = connected.find(item => item.calendar_id === calendar.info.calendar_id)

                  const calendarImage = connected && currentCalendar ? `url(${currentCalendar.image})` : ''
                  const calendarName = connected && currentCalendar ? currentCalendar.summary : ''

                  return (
                    <div className='events-calendar-container' key={index}>
                      <div className='events-calendar-icon' style={{ backgroundImage: calendarImage }} />
                      <div className='events-calendar-name'>{calendarName}</div>
                    </div>
                  )
                })}
              </div>

              <Timeline
                ref={calendarRef}
                events={linkedEventsList}
              />
            </div>
          ) : (
              unlinkedEventsList.map((calendar, index) => {
                const currentCalendar = connected.find(item => item.calendar_id === calendar.info.calendar_id)

                const calendarImage = connected && currentCalendar ? `url(${currentCalendar.image})` : ''
                const calendarName = connected && currentCalendar ? currentCalendar.summary : ''

                return (
                  <div>
                    <div className='events-calendar-container' key={index}>
                      <div className='events-calendar-icon' style={{ backgroundImage: calendarImage }} />
                      <div className='events-calendar-name'>{calendarName}</div>
                    </div>

                    <Timeline
                      ref={calendarRef}
                      events={calendar.events}
                    />
                  </div>
                )
              })
            )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
