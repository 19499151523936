import { gapi, loadAuth2WithProps, } from 'gapi-script';
import { useHistory } from 'react-router-dom'
import API from '../rest/api'
import gconfig from '../gconfig'
import history from '../store/history'

const SET_CONNECTED_CALENDARS = 'SET_CONNECTED_CALENDARS';
const SET_AVAILABLE_CALENDARS = 'SET_AVAILABLE_CALENDARS'
const SET_UPCOMING_EVENTS = 'SET_UPCOMING_EVENTS'

const initialState = {
  connected: [],
  available: [],
  events: [],
};

export function getConnectedCalendars() {
  return async function (dispatch, getState) {
    try {
      const userState = getState().user

      const userInfo = userState.info

      const { calendar_list, events, } = await API.getUserCalendarsList({ calendar_id: userInfo.email })

      const preparedCalendars = calendar_list.map(calendar => calendar.calendar_id === userInfo.email ? ({ ...calendar, summary: 'Default calendar' }) : calendar)

      dispatch(setConnectedCalendars(preparedCalendars))
      dispatch(setUpcomingEvents(events))

      return calendar_list
    } catch (error) {
      throw new Error(error.message)
    }
  };
}

export function getAvailableCalendars(connected) {
  return async function (dispatch, getState) {
    const restrictedCalendars = [
      'en.ukrainian#holiday@group.v.calendar.google.com',
      'addressbook#contacts@group.v.calendar.google.com',
      'uk.ukrainian#holiday@group.v.calendar.google.com'
    ]

    try {
      const userState = getState().user

      const userInfo = userState.info

      const auth2 = await loadAuth2WithProps(gconfig)

      const alreadySigned = auth2.isSignedIn.get()

      if (!alreadySigned) throw new Error('Login required')

      await gapi.client.load('calendar', 'v3', async () => {
        const calendarList = await gapi.client.calendar.calendarList.list({
          maxResults: 250,
          minAccessRole: 'reader',
        })

        const calendars = calendarList.result.items

        const withoutConnected = calendars
          .filter(item => !connected.find(elem => elem.calendar_id === item.id))
          .filter(item => !restrictedCalendars.includes(item.id))

        const withoutSpecialCalendars = withoutConnected.filter(item => item.accessRole !== 'reader')

        const preparedCalendars = withoutSpecialCalendars.map(calendar => calendar.id === userInfo.email ? ({ ...calendar, summary: 'Default calendar' }) : calendar)

        dispatch(setAvailableCalendars(preparedCalendars))
      })
    } catch (error) {
      console.log('------->', error)

      throw new Error(error.message)
    }
  };
}

export function connectCalendar(calendar, history) {
  return async function (dispatch, getState) {
    const { id: calendarId } = calendar

    try {
      // setAvailableLoading(calendarId)

      const { object_id } = await API.getReadableUrl()

      history.push('/user/calendars', {
        selectedCalendar: calendar,
        inviteLink: `${document.location.origin}/j/${object_id}`,
        calendarId,
        objectId: object_id,
      })
    } catch (error) {
      console.log(error)
    } finally {
      // setAvailableLoading(false)
    }
  }
}

export function disconnectCalendar(calendarId) {
  return async function (dispatch, getState) {
    try {
      // setConnectedLoading(calendarId)

      const userState = getState().user

      const userInfo = userState.info

      await API.disconnectCalendar({ calendar_id: calendarId, calendar_user_id: userInfo.email })

      const connected = await dispatch(getConnectedCalendars())
      await dispatch(getAvailableCalendars(connected))
    } catch (error) {
      console.log('api error', error)

      if (error.message === 'Login required') {
        history.push('/')
      }
    } finally {
      // setConnectedLoading(false)
    }
  }
}

export function setConnectedCalendars(payload) {
  return {
    type: SET_CONNECTED_CALENDARS,
    payload
  }
}

export function setAvailableCalendars(payload) {
  return {
    type: SET_AVAILABLE_CALENDARS,
    payload
  }
}

export function setUpcomingEvents(payload) {
  return {
    type: SET_UPCOMING_EVENTS,
    payload
  }
}

const ACTION_HANDLERS = {
  [SET_CONNECTED_CALENDARS]: (state, action) => {
    return { ...state, connected: action.payload }
  },
  [SET_AVAILABLE_CALENDARS]: (state, action) => {
    return { ...state, available: action.payload }
  },
  [SET_UPCOMING_EVENTS]: (state, action) => {
    return { ...state, events: action.payload }
  },
};

export default function UserReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
