
/*global chrome*/
import React, { useEffect, } from 'react'
import { useParams } from "react-router-dom";
import { gapi, loadAuth2WithProps } from 'gapi-script';

import API from '../../rest/api'
import gconfig from '../../gconfig'
import CONFIG from '../../config/index'

function AuthRedirect() {
  const params = useParams()

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    let auth2 = await loadAuth2WithProps(gconfig);

    const currentUser = await auth2.currentUser.get()

    await currentUser.grantOfflineAccess({ 
      "redirect_uri": `${window.origin}/redirect/auth/finish/${params.e_id}`,
      "access_type": "offline",
      'include_granted_scopes': true,
    })
  }

  return (
    <div>

    </div>
  )
}

export default AuthRedirect