/*global chrome*/
import React, { useState, useEffect, } from 'react';
import { useParams } from "react-router-dom";
import './ExtensionChecker.css';
import API from '../../rest/api';
import CONFIG from '../../config/index'

function ExtensionChecker(props) {
  const params = useParams()

  const [loading, setLoadingStatus] = useState(false)
  const [installationFlow, startInstallationFlow] = useState(false)
  const [pinValid, setPinStatus] = useState(false)
  const [enteredPin, setEnteredPin] = useState('')
  const [error, setError] = useState(null)
  const [pinRequired, setPinRequired] = useState(true)
  const [invalidURL, setInvalidURL] = useState(false)

  useEffect(() => {
    initialize()
  }, [])

  async function initialize() {
    setLoadingStatus(true)

    window.onload = async () => {
      const objectId = params.id

      const isValid = await API.isValidUrl({ object_id: objectId })

      if (!isValid) {
        return setInvalidURL(true)
      }

      const data = await API.hasLinkPin({ object_id: objectId })

      if (!data) return
      
      if (!data.pin) {
        setPinRequired(false)

        const userRequest = {
          objectId,
        }

        await tryConnection(userRequest)

        setLoadingStatus(false)
        
        return
      }

      setPinRequired(data.pin)

      setLoadingStatus(false)
    };
  }

  const validatePin = async () => {
    if (enteredPin.length === 0) return

    const objectId = params.id

    const payload = {
      pin: enteredPin,
      object_id: objectId,
    }

    try {
      const result = await API.checkLinkPin(payload)

      if (result) {
        setPinStatus(true)

        const objectId = params.id

        const userRequest = {
          objectId,
        }

        await tryConnection(userRequest)

        window.onfocus = async () => {
          await tryConnection(userRequest)
        }
      } else {
        setError('Invalid PIN code')
        setPinStatus(false)
      }
    } catch (error) {
      setError('Invalid PIN code')
      setPinStatus(false)
    }
  }

  async function tryConnection(userRequest) {
    try {
      await chrome.runtime.sendMessage(CONFIG.EXTENSION_ID, { "type": "ESTABLISH_CONNECTION", payload: userRequest }, (response) => {
        if (!response) {
          startInstallationFlow(true)
        } else {
          startInstallationFlow(false)
        }

        console.log('response', response)
      });
    } catch (err) {
      startInstallationFlow(true)
    }
  }

  function openExtensionPage() {
    window.open('https://chrome.google.com/webstore/detail/nagtoday-smart-calendar/okhohjbbhgljpdkbdmmafiioahefddon')
  }

  if (invalidURL) {
    return (
      <div className="check-centered">
        <div>Nag URL expired, please get new one</div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="check-centered">
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <div className="container">
      {pinRequired ? (
        installationFlow ? (
          <div className='installation-container'>
            <h3>Nag.today extension not found. </h3>
            <h5>Open installation page and go back after install</h5>

            <button
              className='open-button'
              onClick={openExtensionPage}
            >
              OPEN
          </button>
          </div>
        ) : (
            pinValid ? (
              <div className='installation-container'>
                <h3>Nag.today extension configured</h3>
                <h5>Happy usage</h5>
              </div>
            ) : (
                <div className='installation-container'>
                  <h3>Enter calendar link PIN</h3>
                  <input
                    className='submit-pin-input'
                    value={enteredPin}
                    onChange={(e) => setEnteredPin(e.target.value)}
                  />
                  <button className='submit-pin' onClick={validatePin}>
                    Submit
                </button>
                  {error && (
                    <div className='pin-error'>
                      {error}
                    </div>
                  )}
                </div>
              )
          )
      ) : (
          installationFlow ? (
            <div className='installation-container'>
              <h3>Nag.today extension not found. </h3>
              <h5>Open installation page and go back after install</h5>

              <button
                className='open-button'
                onClick={openExtensionPage}
              >
                OPEN
              </button>
            </div>
          ) : (

              <div className='installation-container'>
                <h3>Nag.today extension configured</h3>
                <h5>Happy usage</h5>
              </div>
            ))}
    </div>
  );
}

export default ExtensionChecker;
