import React, { useState, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation, } from "react-router-dom";
import { loadAuth2WithProps } from 'gapi-script';
import './DashboardLayout.css'

import SidePanel from '../DashboardLayout/components/SidePanel/SidePanel'

import { setupSocket, subscribeToChannel, unsubscribeFromChannel } from '../../socket';

import { setUserCredentials, setAuthState, } from '../../reducers/UserReducer'
import { getConnectedCalendars, getAvailableCalendars, setUpcomingEvents, } from '../../reducers/CalendarsReducer'

import API from '../../rest/api'
import gconfig from '../../gconfig'

function DashboardLayout(props) {
  const { children } = props

  const dispatch = useDispatch()

  const history = useHistory();
  let location = useLocation();

  const userInfo = useSelector(state => state.user.info)
  const credentials = useSelector(state => state.user.credentials)
  const connectedCalendars = useSelector(state => state.calendars.connected)
  const availableCalendars = useSelector(state => state.calendars.available)

  const [currentLocation, setCurrentLocation] = useState(null)
  const [connectedLoading, setConnectedLoading] = useState(false)
  const [availableLoading, setAvailableLoading] = useState(false)
  const [popupOpened, setPopupState] = useState(false)

  useEffect(() => {
    if (location.pathname === '/user') {
      history.push("/user/dashboard");
    }

    setCurrentLocation(location.pathname)
  }, [location])

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    let auth2 = await loadAuth2WithProps(gconfig);

    const alreadySigned = auth2.isSignedIn.get()

    if (alreadySigned) {
      if (credentials) {
        try {
          await getAllCalendars()
          return null
        } catch (error) {
          console.log('error - initialize', error, error.message)

          dispatch(setUserCredentials(null))
        }
      }
    } else {
      history.push('/')

      return
    }

    try {
      let auth2 = await loadAuth2WithProps(gconfig);

      const currentUser = await auth2.currentUser.get()

      let offlineParams = await currentUser.grantOfflineAccess({ access_type: 'offline', })

      await API.sendGoogleAuthCode({ ...offlineParams, calendar_id: userInfo.email })

      console.log('------------>', currentUser)

      dispatch(setUserCredentials(currentUser.wc))

      getAllCalendars()
    } catch (error) {
      console.log('error - sendGoogleAuthCode', error)

      if (error.message !== 'No access token') return

      dispatch(setUserCredentials(null))

      let auth2 = await loadAuth2WithProps(gconfig);

      const currentUser = await auth2.currentUser.get()

      let offlineParams = await currentUser.grantOfflineAccess({ access_type: 'offline', })

      await API.sendGoogleAuthCode({ ...offlineParams, calendar_id: userInfo.email })

      dispatch(setUserCredentials(currentUser.tc))

      getAllCalendars()
    }
  }

  useEffect(() => {
    if (connectedCalendars.length > 0) {
      // unsubscribeFromChannel()

      startCalendarRoutine(userInfo.email)
    }
  }, [connectedCalendars])

  // useEffect(() => {
  //   if (connectedCalendars.length > 0) {
  //     connectedCalendars.forEach((calendar, index) => {
  //       setTimeout(() => {
  //         startCalendarRoutine(calendar.user_id)
  //       }, 500)
  //     })
  //   }
  // }, [connectedCalendars])

  useEffect(() => {
    history.push("/user/dashboard");
  }, [])

  async function startCalendarRoutine(userId) {
    try {
      if (!userId) return null

      setupSocket()

      subscribeToChannel(userId, (data) => {
        const { notification, upcoming_events } = data;

        console.log('data ---->', userId, data)

        dispatch(setUpcomingEvents(upcoming_events))
      });
    } catch (error) {
      console.log(error)
    }
  }

  async function getAllCalendars() {
    try {

      setConnectedLoading(true)

      const connected = await dispatch(getConnectedCalendars())

      setConnectedLoading(false)
      setAvailableLoading(true)

      await dispatch(getAvailableCalendars(connected))

    } catch (error) {
      console.log('error - getAllCalendars', error)

      if (error.message === 'Login required') {
        history.push('/')

        return
      }

      throw new Error('No access token')
    } finally {
      setAvailableLoading(false)
      setConnectedLoading(false)
    }
  }

  const signOut = async () => {
    let auth2 = await loadAuth2WithProps(gconfig);

    auth2.signOut()

    dispatch(setAuthState(false))

    history.push('/')
  }

  const openPopup = () => {
    setPopupState(prev => !prev)
  }

  const handleWrapperClick = (e) => {
    if (e.target.id !== 'popup') {
      if (popupOpened) {
        setPopupState(false)
      }
    }
  }

  return (
    <div className='layout-wrapper' onClick={handleWrapperClick}>
      <header>
        <h2>Good morning, {userInfo.firstName}!</h2>

        <div className='right-panel'>
          <div
            className='user-name'
            onClick={openPopup}
          >
            {userInfo.firstName}
          </div>
          <div
            className='user-icon'
            style={{ backgroundImage: `url(${userInfo.imageUrl})` }}
          >
            {popupOpened && (
              <div className='header-popup' id='popup'>
                <div className='header-popup-item'>
                  <div className='header-popup-item-icon settings' />
                  Settings
                </div>
                <div
                  className='header-popup-item'
                  onClick={signOut}
                >
                  <div className='header-popup-item-icon signout' />
                  Sign Out
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      <div className='layout-container'>
        <SidePanel />
        <div className='layout-content'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
