import React from 'react'
import ReactDOM from 'react-dom';
import './EventComponent.css'

import { parseURL } from '../../../../helpers/methods'
import { getFormattedDate, getDiff } from '../../../../helpers/dateFormatter'

function EventComponent({ event, el }) {
  const period = getDiff(event.end, event.start, 'minutes')

  function onClickUrl(url) {
    const urlParams = parseURL(url);

    if (url.includes('zoom.us')) {
      window.open(`zoommtg://zoom.us/join?confno=${urlParams.pathname.slice(3)}&pwd=${urlParams.searchObject.pwd}`)

      return
    }

    window.open(url)
  }

  const content = (
    <div className='event'>
      <div className='event-left'>
        {event && event.extendedProps && event.extendedProps.links && event.extendedProps.links.some(link => link.url.includes('zoom')) && (
          <div
            className='zoom-icon'
            onClick={() => onClickUrl(event.extendedProps.links[0].url)}
          />
        )}

        <div
          className='event-title'
          style={{
            flexDirection: period > 31 ? 'column' : 'row',
            margin: period > 31 ? '15px' : '0 0 0 15px'
          }}
        >
          {getFormattedDate('HH:mm', Date.parse(event.start))} - {getFormattedDate('HH:mm', Date.parse(event.end))}
          <div
            style={{ margin: period > 31 ? 0 : '0 0 0 15px' }}
          >
            {event.title}
          </div>
        </div>
        <div className='event-links' style={period > 31 ? { margin: '15px', } : { flexDirection: 'row', alignItems: 'center' }}>
          <div
            className='heading'
            style={period < 31 ? { margin: '0 15px 0 0', } : {}}
          >
            {event && event.extendedProps && event.extendedProps.links.length > 0 ? 'Event links' : 'No event links'}
          </div>

          {event && event.extendedProps && event.extendedProps.links && event.extendedProps.links.map(link => (
            <div
              className='event-link'
              style={period < 31 ? { width: '40%', margin: '0 10px 0 0', overflowX: 'hidden' } : {}}
              onClick={() => onClickUrl(link.url)}
            >
              {link.url}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  ReactDOM.render(content, el);
  return el;
}

export default EventComponent