import axios from 'axios'
import CONFIG from '../config/index'

const httpClient = axios.create({
    baseURL: `${CONFIG.API_URL}`,
    // baseURL: `http://localhost:4000`,
    headers: {
        'content-type': 'application/json',
        'secret-key': CONFIG.SECRET_KEY
    }
});

export default httpClient;