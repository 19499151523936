import { gapi, loadAuth2WithProps, } from 'gapi-script';
import { useHistory } from 'react-router-dom'
import API from '../rest/api'
import gconfig from '../gconfig'
import history from '../store/history'

const SET_SIDE_MENU_STATE = 'SET_SIDE_MENU_STATE';

const initialState = {
  sideMenuOpened: false,
};

export function setSideMenuState(payload) {
  return {
    type: SET_SIDE_MENU_STATE,
    payload
  }
}

const ACTION_HANDLERS = {
  [SET_SIDE_MENU_STATE]: (state, action) => {
    return { ...state, sideMenuOpened: action.payload }
  },
};

export default function UserReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
