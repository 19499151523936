import {combineReducers} from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import UserReducer from '../reducers/UserReducer';
import CalendarsReducer from '../reducers/CalendarsReducer';
import ControlReducer from '../reducers/ControlReducer'

const userConfig = {
  key: 'user',
  storage,
}

export default combineReducers({
  user: persistReducer(userConfig, UserReducer),
  calendars: CalendarsReducer,
  control: ControlReducer,
});