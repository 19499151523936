import React, { useState, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadAuth2WithProps } from 'gapi-script';
import { useHistory } from "react-router-dom";
import './Login.css'

import gconfig from '../../gconfig'

import { setAuthState, setUserInfo, } from '../../reducers/UserReducer'

function Login() {
  const history = useHistory();
  const dispatch = useDispatch()

  const [signed, setSigned] = useState(false)
  const [loading, setLoadingStatus] = useState(true)
  const [googleAuth, setGoogleAuth] = useState(null)

  useEffect(() => {
    initialize()
  }, [])

  async function initialize() {
    setLoadingStatus(true)

    let auth2 = await loadAuth2WithProps(gconfig);

    const alreadySigned = auth2.isSignedIn.get()

    setGoogleAuth(auth2)

    if (alreadySigned) {
      const profile = await auth2.currentUser.get().getBasicProfile();

      const user = {
        id: profile.getId(),
        fullname: profile.getName(),
        firstName: profile.getGivenName(),
        lastName: profile.getFamilyName(),
        imageUrl: profile.getImageUrl(),
        email: profile.getEmail(),
      }

      setSigned(true)

      dispatch(setAuthState(true))
      dispatch(setUserInfo(user))

      history.push("/user/dashboard");
    }

    setLoadingStatus(false)
  }

  async function handleSignIn() {
    try {
      await googleAuth.signIn()

      setSigned(true)

      history.push("/user/dashboard");
    } catch (error) {
      console.log('Cannot SignIn', error)
    }
  }

  if (loading) {
    return (
      <div className="centered">
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <div className='login-container'>
      <div className='login-left'>
        <div className='login-video-placeholder'>
          <div className='login-youtube-icon'></div>
        </div>
        <h5>
          We prepared a quick intro video to help you get started
        </h5>
      </div>
      <div className='login-right'>
        <header>
          <h1>Welcome to Nag.Today Dashboard!</h1>
          <div className='login-steps-container'>
            <h5>To get started, follow these simple steps:</h5>

            <ul>
              <li><span>Step 1:</span>Allow us to access your Google Calendar events</li>
              <li><span>Step 2:</span> Connect one calendar per child and generate a Nag URL </li>
              <li><span>Step 3:</span> Paste the Nag URL in the Nag.Today Extension</li>
            </ul>
          </div>
        </header>

        <button
          className="authbutton"
          onClick={handleSignIn}
        >
          Connect my Google Calendar
        </button>
      </div>
    </div>
  )
}

export default Login
