const SET_AUTH_STATE = 'SET_AUTH_STATE';
const SET_USER_INFO = 'SET_USER_INFO'
const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS'

const initialState = {
  loggedIn: false,
  info: null,
  credentials: null,
};

export function setAuthState(payload) {
  return {
    type: SET_AUTH_STATE,
    payload
  }
}

export function setUserInfo(payload) {
  return {
    type: SET_USER_INFO,
    payload
  }
}

export function setUserCredentials(payload) {
  return {
    type: SET_USER_CREDENTIALS,
    payload
  }
}

const ACTION_HANDLERS = {
  [SET_AUTH_STATE]: (state, action) => {
    return { ...state, loggedIn: action.payload }
  },
  [SET_USER_INFO]: (state, action) => {
    return { ...state, info: action.payload }
  },
  [SET_USER_CREDENTIALS]: (state, action) => {
    return { ...state, credentials: action.payload }
  },
};

export default function UserReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
