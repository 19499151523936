import React, { useEffect, } from 'react'
import { useHistory, useLocation, } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import '@fullcalendar/core/main.css';
import '@fullcalendar/timegrid/main.css';
import './Timeline.css'

import { getFormattedDate, } from '../../../../helpers/dateFormatter'

import EventComponent from '../EventComponent/EventComponent'

function Timeline(props) {
  const { ref, events, } = props

  return (
    <FullCalendar
      ref={ref}
      defaultView="timeGridDay"
      scrollTime={getFormattedDate('HH:mm:ss', new Date().setHours(new Date().getHours() - 1))}
      plugins={[timeGridPlugin]}
      weekends
      events={events}
      height={650}
      eventRender={EventComponent}
      expandRows={true}
      allDaySlot={false}
      nowIndicator
      nowIndicatorClassNames={'now-indicator'}
    />
  )
}

export default Timeline