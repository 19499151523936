export const allowedFileTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg'
]

export const eventColors = [
  '#3788D8',
  '#FAC863',
  '#20232A',
  '#FF756B',
  '#45D09E',
  '#AE3F7B',
  '#939598',
  '#FEAC92',
  '#2E3F7F',
]