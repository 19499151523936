import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)
// import 'dayjs/locale/uk'
// dayjs.locale('uk')
dayjs.extend(isBetween)

export function getFormattedDate(format, date, emptyValuePlaceholder) {
  if (date) {
    if (typeof date === 'object') {
      return (
        dayjs()
          .hour(date.hours)
          .minute(date.minutes)
          .second(date.seconds)
          .format(format)
      )
    }

    return dayjs(date).format(format)
  }

  if(emptyValuePlaceholder) {
    return emptyValuePlaceholder
  }

  return dayjs().format(format)
}

export function getIsBetween(compared, start, end) {
  if (compared && start && end) {
    return dayjs(compared).isBetween(start, end)
  }

  if(compared && !(start && end)) {
    return dayjs(compared).isBetween(dayjs().startOf('day'), dayjs().endOf('day'))
  }

  return dayjs().isBetween(start, end)
}

export function getDiff(start, end, unit) {
  return dayjs(start).diff(dayjs(end), unit)
}