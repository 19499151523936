import React from 'react'
// import { Link } from "react-router-dom";
import './UnknownRoute'

function UnknownRoute() {
  return (
    <div className='unknown'>
      Unknown address

      {/* <Link to={'/'}> */}
        <button>
          Go to the main page
        </button>
      {/* </Link> */}
    </div>
  )
}

export default UnknownRoute