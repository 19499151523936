import httpClient from './index'

const API = {
  async connectCalendar(payload) {
    return await asyncPostCall(`/api/users/connectCalendar`, payload)
  },
  async getUserId(payload) {
    return await asyncPostCall(`/api/users/getUserId`, payload)
  },
  async setUrlPin(payload) {
    return await asyncPostCall(`/api/users/setUrlPin`, payload)
  },
  async setLinkPin(payload) {
    return await asyncPostCall(`/api/users/setLinkPin`, payload)
  },
  async hasLinkPin(payload) {
    return await asyncPostCall(`/api/users/hasLinkPin`, payload)
  },
  async isValidUrl(payload) {
    return await asyncPostCall(`/api/users/isValidUrl`, payload)
  },
  async checkLinkPin(payload) {
    return await asyncPostCall(`/api/users/checkLinkPin`, payload)
  },
  async getUserCalendarsList(payload) {
    return await asyncPostCall(`/api/users/getUserCalendarsList`, payload)
  },
  async disconnectCalendar(payload) {
    return await asyncPostCall(`/api/users/disconnectCalendar`, payload)
  },
  async getReadableUrl(payload) {
    return await asyncPostCall(`/api/users/getReadableUrl`, payload)
  },
  async sendGoogleAuthCode(payload) {
    return await asyncPostCall(`/api/users/sendGoogleAuthCode`, payload)
  },
  async updateCalendar(payload) {
    return await asyncPostCall(`/api/users/updateCalendar`, payload)
  },
  async generateNewURL(payload) {
    return await asyncPostCall(`/api/users/generateNewURL`, payload)
  },
}

export default API

async function asyncPostCall(event, payload) {
  try {
    console.log('Rest call ==>', event, payload);
    const result = await httpClient.post(event, { ...payload });
    console.log('Response ==>', event, result.data);

    return result.data
  } catch (e) {
    throw e.response.data.error
  }
}
