import dev from './dev'
import prod from './prod'

import _ from 'lodash'
const NODE_ENV = process.env.NODE_ENV || 'staging';

let gconfig = _.merge(dev);

if(NODE_ENV === 'production'){
  gconfig = _.merge(gconfig, prod)
}

gconfig = _.merge(gconfig);

export default gconfig;
