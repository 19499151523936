import React from 'react'
import './ErrorBoundary.css'

function ErrorBoundary(props) {
  const { hasError, children, } = props

  if (hasError) {
    return (
      <div className='error-container'>
        Unknown error
        <button>
          Go to the main page
        </button>
      </div>
    )
  }

  return children
}

export default ErrorBoundary