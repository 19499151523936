import React, { useEffect, } from 'react'
import { useHistory, useLocation, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import './SidePanel.css'
// import ClipLoader from "react-spinners/ClipLoader";

import { connectCalendar, disconnectCalendar } from '../../../../reducers/CalendarsReducer'
import { setSideMenuState, } from '../../../../reducers/ControlReducer'

function SidePanel(props) {
  const {
    connectedLoading, availableLoading,
  } = props

  const history = useHistory();
  const dispatch = useDispatch()

  const connected = useSelector(state => state.calendars.connected)
  const available = useSelector(state => state.calendars.available)
  const sideMenuOpened = useSelector(state => state.control.sideMenuOpened)

  const viewConnectionLink = (calendar) => {
    closeSidebar()
    history.push('/user/calendars', {
      selectedCalendar: calendar,
      inviteLink: `${document.location.origin}/j/${calendar.object_id}`,
      calendarId: calendar.calendar_id,
      objectId: calendar.object_id,
      heading: 'Viewing',
      pin: calendar.pin,
      image: calendar.image,
      connections: calendar.connections,
      hasPin: calendar.hasPin,
    })
  }

  const gotoConnect = (calendar) => {
    closeSidebar()

    dispatch(connectCalendar(calendar, history))
  }

  const closeSidebar = () => {
    dispatch(setSideMenuState(false))
  }

  let sidePanelClasses = classNames({
    'side-panel-container': true,
    'opened': sideMenuOpened,
  });

  return (
    <div className={sidePanelClasses}>
      <div
        className='sidebar-close'
        onClick={closeSidebar}
      />
      <div className='heading'>
        Connected Calendars
      </div>

      <div className='side-calendars-list'>
        {connectedLoading === true && 'Loading...'}
        {connected.length === 0 && !connectedLoading && (
          <div className='none-yet'>None yet!</div>
        )}
        {connected.map((calendar, index) => (
          <div className='side-calendars-list-item' key={index}>
            <div className='left-side'>
              <div className='calendar-icon' />
              <div className='calendar-name'>
                {calendar.summary}
              </div>
            </div>

            <div
              className='side-calendars-list-item-icon settings'
              onClick={() => viewConnectionLink(calendar)}
            />
            <div
              className='side-calendars-list-item-icon disconnect'
              onClick={() => dispatch(disconnectCalendar(calendar.calendar_id))}
            />
          </div>
        ))}
      </div>

      <div className='heading top-margin'>
        Available Calendars
      </div>

      <div className='side-calendars-list'>
        {availableLoading === true && 'Loading...'}
        {available.map((calendar, index) => (
          <div className='side-calendars-list-item' key={index}>
            <div className='left-side'>
              <div className='calendar-icon' />
              <div className='calendar-name'>{calendar.summary}</div>
            </div>

            <button
              className='action-button'
              onClick={() => gotoConnect(calendar)}
            >
              <div>Connect</div>

              {index === 0 && connected.length === 0 && history.location.pathname === '/user/dashboard' && (
                <div className='tip-container'>
                  <div className='tip-arrow' />
                  <div className='tip-heading'>
                    Connected Calendars
                  </div>
                  <div className='tip-content'>
                    Let’s connect one or more calendars! Click “Connect” in the list of calendars below to start. You can connect as many calendars as you need!.
                  </div>
                  <div className='tip-content'>
                    Google will ask for your permission to connect, remember to select the correct account, and then click “Allow”.
                  </div>
                </div>
              )}
            </button>
          </div>
        ))}
      </div>

      {/* {connected.length === 0 && (
        <>
          <div className='heading top-margin'>
            Connected Calendars
          </div>
          <div className='service-description'>
            Let’s connect one or more calendars! Click “Connect” in the list of calendars below to start. You can connect as many calendars as you need!.
          </div>

          <div className='service-description'>
            Google will ask for your permission to connect, remember to select the correct account, and then click “Allow”
          </div>
        </>
      )} */}
    </div>
  )
}

export default SidePanel
